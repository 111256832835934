import React from "react";
import "../Style/_about.scss";

const About = () => {
  return (
    <div className="about-container section">
      <h3>À propos de moi</h3>
      <div className="about-description">
        <div className="about-image-container">
          <img src="./assets/img/flo.webp" alt="Profile" className="about-image" />
        </div>
        <div className="about_content">
          <p>
            Après un parcours atypique mêlant bien-être, art, et accompagnement des enfants, j'ai découvert ma vocation : apporter énergie et douceur. Grâce à
            une formation approfondie, j'œuvre aujourd'hui à améliorer votre bien-être par la réflexologie, une méthode douce pour faire face au stress, à la
            fatigue et aux déséquilibres énergétiques.
          </p>
          <br></br>
          <p>
            Avec plus de vingt ans d'expérience personnelle sur la gestion de l'énergie et de l'esprit, je mets à votre service ma compréhension profonde des
            bienfaits de cette pratique sur le corps et l'esprit.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
