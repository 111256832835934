import React from "react";
import "../Style/_reviewCard.scss";

const ReviewCard = ({ name, review }) => {
  // Convertir les retours à la ligne en <br> tags
  const formattedReview = review.split("\n").map((str, index) => (
    <React.Fragment key={index}>
      {str}
      <br />
    </React.Fragment>
  ));

  return (
    <div className="review-card">
      <blockquote>{formattedReview}</blockquote>
      <footer>
        <cite>{name}</cite>
      </footer>
    </div>
  );
};

export default ReviewCard;
