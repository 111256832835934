import React from "react";

import "../Style/_services.scss";

// =======================================================
// MainContent class as Javascript function
// =======================================================
function MainContent() {
  return (
    <>
      <div className="services-container section">
        <h3>La réflexologie plantaire : un soin adapté à tous</h3>
        <br></br>
        <br></br>
        <div className="service-section">
          <h4>Pour les adultes:</h4>
          <p>Des séances de 45 à 60 minutes pour libérer les tensions et favoriser une meilleure circulation énergétique dans le corps.</p>
        </div>
        <div className="service-section">
          <h4>Pour les enfants:</h4>
          <p>Des séances adaptées d'environ 30 minutes, pour un moment de détente et d'équilibre.</p>
        </div>
        <div className="service-section">
          <h4>À domicile:</h4>
          <p>Je me déplace avec tout le matériel nécessaire, m'adaptant à votre espace pour un confort optimal.</p>
        </div>
        <div className="service-section">
          <h4>En entreprise:</h4>
          <p>Des consultations sur place pour un moment de bien-être accessible au travail.</p>
        </div>
        <div className="deroulement">
          <h4>Déroulement d’une séance:</h4>
          <p>Avant tout, préparez environ 30 minutes avant ma présence vos pieds à l’aide d’un bain d’eau tiède et savon.</p>
          <p>Nous prendrons un temps d’échange afin de connaître vos attentes sur la séance, puis vous vous installerez sur la table de massage.</p>
          <p>Le soin dure environ 45 à 60 minutes. Pour les enfants, les séances sont d’environ 30 minutes.</p>
        </div>
        <br></br>
        <br></br>
        <div className="service-section">
          <h4>Tarifs:</h4>
          <p>
            <strong className="less-bold">Particuliers :</strong> 55 euros la séance.
          </p>
          <p>
            <strong className="less-bold">Enfants :</strong> 30 euros la séance.
          </p>
          <p>
            <strong className="less-bold">Entreprises :</strong> Tarif spécial à 45 euros pour une heure de découverte et soin.
          </p>
        </div>
      </div>
    </>
  );
}

export default MainContent;
