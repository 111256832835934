import React from "react";
import "../Style/_Header.scss";
import "../Style/_section.scss";

// =======================================================
// MainContent class as Javascript function
// =======================================================
function Header() {
  return (
    <>
      <div className="Header section">
        <div className="titles">
          <h1>Florence Hand</h1>
          <h2>Reflexologie plantaire à domicile - Orléans</h2>
        </div>
        <img src="./assets/logos/heart_upscale.png" alt="logo" className="header_image" />
      </div>
    </>
  );
}

export default Header;
