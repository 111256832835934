import React from "react";

import Header from "./Components/Header";
import Introduction from "./Components/Introduction";
import About from "./Components/About";
import Services from "./Components/Services";
import Rdv from "./Components/rdv";
import ReviewContent from "./Components/Reviews";

import "./App.css";

// =======================================================
// App class (ES6)
// =======================================================
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // -----------------------------------------------------
  // Main rendering
  // -----------------------------------------------------
  render() {
    return (
      <div className="App">
        <Header />
        <Introduction />
        <About />
        <Services />
        <Rdv />
        <ReviewContent />
      </div>
    );
  }
}

// =======================================================
export default App;
