import React from "react";
import "../Style/_rdv.scss";

const Rdv = () => {
  return (
    <div className="rdv-container section">
      <h3>Prise de rendez-vous</h3>
      <div className="rdv">
        <br></br>

        <p>Du Lundi au Vendredi, de 9h à 18h.</p>
        <br></br>
        <p>Par telephone: 06 74 44 02 89</p>
        <p>
          Par mail: <a href="mailto:florence.hand@gmail.com">florence.hand@gmail.com</a>
        </p>
        <br></br>
      </div>
    </div>
  );
};

export default Rdv;
