import React from "react";
import "../Style/_introduction.scss";

// =======================================================
// IntroductionCard class as Javascript function
// =======================================================
function IntroductionCard(props) {
  const { imagePath, title, text } = props; // Destructuring props

  return (
    <>
      <div className="introduction_card">
        <img src={imagePath} alt="Avatar" />
        <div className="card_text_container">
          <h4>
            <b>{title}</b>
          </h4>
          <p>{text}</p>
        </div>
      </div>
    </>
  );
}

export default IntroductionCard;
