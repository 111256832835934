import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "../Style/_reviewForm.scss";

const ReviewForm = ({ addReview }) => {
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formattedComment = `"${comment}"`;
    addReview({ name, comment: formattedComment });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="name_field">
        <label htmlFor="name">Nom:</label>
        <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
      </div>
      <div className="reviex_field">
        <label htmlFor="review">Avis:</label>
        <textarea id="review" value={comment} onChange={(e) => setComment(e.target.value)} required />
      </div>
      <div>
        <ReCAPTCHA
          sitekey="6Ld4KfYpAAAAAG2ruwjfATWLtnTYnqy1zYoQw_q0" // clef de site reCAPTCHA
          onChange={(value) => setRecaptchaValue(value)}
        />
      </div>
      <button type="submit">Ajouter un avis</button>
    </form>
  );
};

export default ReviewForm;
