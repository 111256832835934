import React from "react";
import IntroductionCard from "./IntroductionCard";
import "../Style/_introduction.scss";

// =======================================================
// Introduction class as Javascript function
// =======================================================
function Introduction() {
  return (
    <>
      <div className="introduction section">
        <h3>Une approche holistique pour votre bien-être</h3>
        <p>
          La réflexologie plantaire est une thérapie manuelle qui consiste à appliquer une pression sur des points réflexes spécifiques situés sur les pieds.
          Ces points correspondent à différentes parties du corps et sont censés refléter l'état de santé global de l'individu. En stimulant ces points
          réflexes, la réflexologie plantaire vise à améliorer les points suivants :
        </p>

        <div className="introduction_gallery">
          <IntroductionCard
            imagePath="./assets/img/Stress.webp"
            title="Réduction du stress et de l'anxiété"
            text="En procurant une relaxation profonde, elle aide à diminuer les niveaux de stress."
          />
          <IntroductionCard
            imagePath="./assets/img/circulation.webp"
            title="Amélioration de la circulation"
            text="En stimulant les points réflexes, elle favorise une meilleure circulation sanguine et lymphatique."
          />
          <IntroductionCard
            imagePath="./assets/img/Insomnie.webp"
            title="Bonification du sommeil"
            text="Elle aide à combattre l'insomnie et favorise un sommeil réparateur."
          />
          <IntroductionCard
            imagePath="./assets/img/douleur_bois.webp"
            title="Soulagement des douleurs"
            text=" Efficace pour atténuer divers types de douleurs, y compris les maux de tête, les douleurs menstruelles ou encore les tensions musculaires."
          />
          <IntroductionCard
            imagePath="./assets/img/Virus.webp"
            title="Renforcement du système immunitaire"
            text="En stimulant les zones réflexes liées aux organes vitaux, elle contribue à renforcer les défenses naturelles du corps."
          />
        </div>

        <p>
          Chaque séance est personnalisée pour répondre aux besoins propre de la personne, en tenant compte de ses conditions physiques et émotionnelles. Que
          vous cherchiez à vous détendre, à soulager des douleurs spécifiques ou à améliorer votre bien-être général, la réflexologie plantaire offre un chemin
          vers une meilleure santé, sans l'usage de médicaments.
        </p>
        <p>
          Je vous invite à découvrir les bienfaits de la réflexologie plantaire pour votre propre bien-être. Contactez-moi pour organiser votre séance
          découverte et débuter votre voyage vers une santé optimale et un équilibre retrouvé.
        </p>
      </div>
    </>
  );
}

export default Introduction;
