import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import ReviewForm from "./ReviewForm";
import ReviewCard from "./ReviewCard";

import "../Style/_reviews.scss";

Modal.setAppElement("#root");

const ReviewContent = () => {
  const [reviews, setReviews] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Récupérer les avis depuis l'API lors du montage du composant
    axios
      .get("https://flo.zihand.fr/db.php")
      .then((response) => {
        //console.log(response.data); // Ajout de console.log pour vérifier les données
        if (Array.isArray(response.data)) {
          // console.log("Get reviews:", response.data);
          if (response.data.length > 0) {
            setReviews(response.data);
          } else {
            console.log("La réponse de l'API est un tableau vide :", response.data);
          }
        } else if (response.data === null) {
          // Ajout d'unegth
          setReviews(response.data);
        } else {
          console.error("La réponse de l'API n'est pas un tableau :", response.data);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des avis :", error);
      });
  }, []);

  const addReview = (newReview) => {
    //console.log("addReview", newReview);
    axios
      .post("https://flo.zihand.fr/db.php", newReview)
      .then((response) => {
        console.log(response.data);
        if (response.data.message === "Review added successfully") {
          setReviews((prevReviews) => [...prevReviews, newReview]);
        } else {
          console.error("Failed to add review");
        }
      })
      .catch((error) => {
        console.error("Failed to add review :", error);
      });

    setIsModalOpen(false); // Ferme le modal après l'ajout d'un avis
  };

  const toggleModal = () => {
    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  };

  return (
    <>
      <div className="reviews_container section">
        <h3>Vos Avis</h3>
        <br></br>
        <br></br>
        <button onClick={toggleModal}>Laisser un avis</button>
        <Modal isOpen={isModalOpen} onRequestClose={toggleModal} contentLabel="Laisser un avis" className="Modal" overlayClassName="Overlay">
          <button onClick={toggleModal} className="close-button">
            X
          </button>
          <ReviewForm addReview={addReview} />
        </Modal>

        <div className="review_list">
          {Array.isArray(reviews) && reviews.map((review, index) => <ReviewCard key={index} name={review.name} review={review.comment} />)}
        </div>
      </div>
    </>
  );
};

export default ReviewContent;
